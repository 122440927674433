<script setup>

import {computed, ref} from "vue";
import {useMainStore}  from "../stores/main";
import {storeToRefs}   from "pinia";

let props = defineProps(["item"]);
const emit = defineEmits(['change', "change-quantity", "show-details"])

//format to 2 decimal places
let subtotal = computed(() => (props.item.price * qty.value).toFixed(2) );


let store = useMainStore();
let {  copydeck, lang, currentOrder, catalogType, computedCart } = storeToRefs(store);

let quantityCart = computed(() =>
{
    let ps = computedCart.value.filter ( itemCart => itemCart.id === props.item.id);

    let quantity = ps.reduce( (acc, item) => acc + parseInt(item.quantity), 0);

    return quantity;
} );

function decreaseQuantity()
{
    let newQty = qty.value - 1;
    emit('change-quantity', newQty)
}

function increaseQuantity()
{
    let newQty = qty.value + 1;
    emit('change-quantity', newQty)
}

let qty = computed (
    {
        get()
        {
            let index = currentOrder.value.findIndex( (p) => p.id === props.item.id);

            if (index < 0)
            {
                return 0;
            }
            return currentOrder.value[index].quantity;
        },
        set(value)
        {
            emit('change', value)
        }
    }

);

function showDetail ()
{
    emit("show-details", props.item.id);
}
</script>

<template>
    <div class="row y-gap-20 justify-between items-center pt-5 pb-5 border-bottom-light">
        <div class="col-md-4 col-8">
            <div class="d-flex items-center">
                <div class="">
                    <div class="size-100 bg-image rounded-8 js-lazy pointer-click" @click="showDetail" >
                        <i class="text-20 icon icon-search tl-img"></i>
                        <img :src="item.img_small" alt="">
                    </div>
                </div>
                <div class="fw-500 text-dark-1 ml-30 pointer-click" @click="showDetail">{{ item.name }}  </div>
            </div>
        </div>

        <div class="col-md-2 col-4 md:mt-15 md:d-flex justify-content-end">
            <div class="">
                <div class="shopCart-products__title d-none md:d-block mb-10">
                    {{ copydeck.product_list.price[lang ]}}
                </div>
                <p>{{ parseFloat(item.price).toFixed(2) }} $</p>
            </div>
        </div>

        <div class="col-md-2 col-8" v-if="catalogType == 'list'">
            <div class="">
                <div class="shopCart-products__title d-none md:d-block mb-10">
                    {{ copydeck.product_list.quantity[lang ]}}
                </div>

                <div class="input-counter md:mt-20 js-input-counter">
                    <input class='input-counter__counter' type="number" placeholder="value..." v-model="qty" />

                    <div class="input-counter__controls">
                        <button class='input-counter__up js-down' :disabled="qty <= 0" @click="decreaseQuantity()">
                            <i class='icon' data-feather="minus">-</i>
                        </button>

                        <button class='input-counter__down js-up'  :disabled="qty >= 99" @click="increaseQuantity()">
                            <i class='icon' data-feather="plus">+</i>
                        </button>
                    </div>
                </div>
            </div>
        </div>


        <div class="col-md-2 col-4 md:d-flex justify-content-end" v-if="catalogType == 'list'">
            <div class="">
                <div class="shopCart-products__title d-none md:d-block mb-10">
                    {{ copydeck.product_list.subtotal[lang ]}}
                </div>

                <p style="text-align:right">{{ subtotal }}&nbsp;$</p>
            </div>
        </div>
        <div class="col-md-2 col-4 md:d-flex justify-content-end" v-if="catalogType == 'checkout'">
            <div class="">
                <div class="shopCart-products__title d-none md:d-block mb-10">
                    {{ copydeck.product_list.subtotal[lang ]}}
                </div>

                <p style="text-align:right" v-if="quantityCart > 0">{{ quantityCart }}&nbsp;</p>
            </div>
        </div>




        <div class="col-md-2 col-4 md:d-flex justify-content-end" v-if="catalogType == 'checkout'">
            <div class="">
                <button class="button -md -blue-1 text-white mr-2 me-2" @click="showDetail"> {{ copydeck.checkout.btn_view_options[lang] }}</button>
            </div>
        </div>


    </div>

</template>

<style scoped>
.tl-img
{
    position: relative;
    top: 30px;
    left: 3px;
    color: white;
    background-color:rgba(200,200,200, 0.75);
    padding:2px;
}
</style>
