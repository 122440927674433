<script setup>
import { ref } from 'vue';
import { useMainStore } from '../stores/main';
import { storeToRefs } from 'pinia';

let props = defineProps(['variation','checkoutItem']);
let emits = defineEmits(['change']);

let store = useMainStore();
const { lang } = storeToRefs(store);


function getImageFormat (image)
{
    return image.formats.find( f => f.name === 'radio').url;
}

function onChange (option, variation)
{
    props.checkoutItem[props.variation.key] = option.target.id;
    emits("change", option.target.id, variation);
}

</script>

<template>
    <div class="col-auto">
        <div class="variation-item" v-for="item in variation.list" :key="item.name[lang]">
            <input
                type="radio"
                :id="item.key"
                :name="variation.key"
                :value="item.key"
                class="radio-input"
                @change="(option) => onChange(option, variation)"
            />
            <label :for="item.key" class="radio-label">
                <img :src="getImageFormat(item.image)" :alt="item.name[lang]" class="variation-image" />
            </label>
        </div>
    </div>
</template>

<style scoped>
.variation-item {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
}

.radio-input {
    display: none;
}

.radio-label {
    cursor: pointer;
}

.radio-input:checked + .radio-label .variation-image {
    border: 2px solid var(--color-blue-1);;
}

.variation-image {
    width: 100%;
    max-width: 100px;
    min-width: 100px;
    border: 2px solid transparent;
    border-radius: 5px;
    transition: border 0.3s ease;
}

.col-auto {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
</style>
