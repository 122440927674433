<script setup>

import {computed, ref} from "vue";
import Product from "./product.vue";
import Products_list_head from "./products_list_head.vue";
import {useMainStore} from "../stores/main";
import {storeToRefs} from "pinia";
import Products_list_total from "./products_list_total.vue";
import Order_details from "./order_details.vue";


let store = useMainStore();
let { order, total, mode, copydeck, lang, userInfo, formIsReady, catalog, userInfoErrors } = storeToRefs(store);
let { submitOrder } = store;

let client  = ref({})

</script>

<template>

        <section class="page-header -type-1">
            <div class="container">
                <div class="page-header__content">
                    <div class="row justify-center text-center">
                        <div class="col-auto max-w-50-md">
                            <div data-anim="">

                                <h1 class="page-header__title">{{ copydeck.checkout.title[lang] }}</h1>

                            </div>

                            <div data-anim="">

                                <p class="page-header__text " v-html="catalog.checkout.details[lang]"></p>

                            </div>

                            <div data-anim="" v-if="Object.keys(userInfoErrors).length > 0">
                                <p class="page-header__text text-red-1" v-html="copydeck.checkout.has_errors[lang]"></p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="layout-pt-md layout-pb-lg">
            <div class="container">
                <div class="row y-gap-50">
                    <div class="col-lg-7">
                        <div class="shopCheckout-form">
                            <form action="post" class="contact-form row x-gap-30 y-gap-30">
                                <div class="col-12">
                                    <h5 class="text-20"> {{ copydeck.form.info_buyer[lang] }}</h5>
                                </div>
                                <div class="col-sm-6">
                                    <label class="text-16 lh-1 fw-500 text-dark-1 mb-10">{{ copydeck.form.first_name[lang] }} <span v-if="userInfoErrors['first_name']" class="text-red-1 ml-2 text-11">{{ copydeck.checkout.required[lang] }}</span></label>
                                    <input type="text" name="firstName" :placeholder="copydeck.form.first_name[lang]" v-model="client.first_name">
                                </div>
                                <div class="col-sm-6">
                                    <label class="text-16 lh-1 fw-500 text-dark-1 mb-10">{{ copydeck.form.last_name[lang] }} <span v-if="userInfoErrors['last_name']" class="text-red-1 ml-2 text-11">{{ copydeck.checkout.required[lang] }}</span></label>
                                    <input type="text" name="lastName" :placeholder="copydeck.form.last_name[lang]" v-model="client.last_name">
                                </div>


<!--                                <div class="col-12">-->
<!--                                    <label class="text-16 lh-1 fw-500 text-dark-1 mb-10">{{ copydeck.form.address[lang] }}</label>-->
<!--                                    <input type="text" name="address" :placeholder="copydeck.form.address[lang]" v-model="userInfo.address">-->
<!--                                </div>-->

<!--                                <div class="col-sm-6">-->
<!--                                    <label class="text-16 lh-1 fw-500 text-dark-1 mb-10">{{ copydeck.form.apt[lang] }}</label>-->
<!--                                    <input type="text" name="apartment" :placeholder="copydeck.form.apt[lang]" v-model="userInfo.apt">-->
<!--                                </div>-->

<!--                                <div class="col-sm-6">-->
<!--                                    <label class="text-16 lh-1 fw-500 text-dark-1 mb-10">{{ copydeck.form.city[lang] }}</label>-->
<!--                                    <input type="text" name="city" :placeholder="copydeck.form.city[lang]" v-model="userInfo.city">-->
<!--                                </div>-->


<!--                                <div class="col-sm-6">-->
<!--                                    <label class="text-16 lh-1 fw-500 text-dark-1 mb-10">{{ copydeck.form.postal_code[lang] }}</label>-->
<!--                                    <input type="text" name="postal_cde" :placeholder="copydeck.form.postal_code[lang]" v-model="userInfo.postal_code">-->
<!--                                </div>-->

<!--                                <div class="col-sm-6">-->
<!--                                    <label class="text-16 lh-1 fw-500 text-dark-1 mb-10">{{ copydeck.form.phone[lang] }}</label>-->
<!--                                    <input type="text" name="phone" :placeholder="copydeck.form.phone[lang]" v-model="userInfo.phone">-->
<!--                                </div>-->

                                <div class="col-12">
                                    <label class="text-16 lh-1 fw-500 text-dark-1 mb-10">{{ copydeck.form.email[lang] }} <span v-if="userInfoErrors['email']" class="text-red-1 ml-2 text-11">{{copydeck.checkout.required[lang]}}</span></label>
                                    <input type="email" name="email" :placeholder="copydeck.form.email[lang]" v-model="client.email">
                                </div>

                                <div class="col-sm-6">
                                    <label class="text-16 lh-1 fw-500 text-dark-1 mb-10">{{ copydeck.form.kid_school_group[lang] }}</label>
                                    <input type="text" name="classroom" :placeholder="copydeck.form.kid_school_group[lang]" v-model="client.classroom">
                                </div>

                                <div class="col-sm-6">
                                    <label class="text-16 lh-1 fw-500 text-dark-1 mb-10">{{ copydeck.form.kid_name[lang] }}</label>
                                    <input type="text" name="childname" :placeholder="copydeck.form.kid_name[lang]" v-model="client.child_name">
                                </div>

                                <div class="col-12">
                                    <h5 class="text-20 fw-500 pt-30">{{ copydeck.form.additional_information[lang] }}</h5>
                                </div>
                                <div class="col-12">
                                    <label class="text-16 lh-1 fw-500 text-dark-1 mb-10">{{ copydeck.form.notes[lang] }}</label>
                                    <textarea name="notes" id="form_notes" rows="8" :placeholder="copydeck.form.notes[lang]" v-model="client.notes"></textarea>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="col-lg-5">
                        <div class="">

                            <order_details :order="order" :total="total"></order_details>

                            <div class="py-30 px-30 bg-white mt-30 border-light rounded-8 bg-light-4">
                                <h5 class="text-20 fw-500">
                                    {{ copydeck.payment.title[lang] }}
                                </h5>

                                <div class="mt-30">
                                    <p class="pl-5 mt-25" v-html="catalog.payment.details[lang]"></p>
                                </div>

                            </div>

                            <div class="mt-30 d-flex justify-content-center">

                                <button
                                    @click="() => submitOrder(client)"

                                    class="button -md -blue-1  text-white"> {{ copydeck.button.place_order[lang] }}</button>

                            </div>

                            <div class="mt-30 d-flex justify-content-center">

                                <router-link
                                :to="{name: `products-${lang}`}">
                                <button
                                    @click="mode = 'order'"
                                    class="button -md -blue-1 text-white"> {{ copydeck.button.edit_order[lang] }} </button>

                                </router-link>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

</template>

<style scoped>

</style>
