<script setup>

import {storeToRefs} from "pinia";
import {useMainStore} from "../stores/main";

let store = useMainStore();
let { copydeck, lang, catalogType } = storeToRefs(store);


</script>

<template>
    <div class="row y-gap-20 justify-between items-center">
        <div class="col-md-4">
            <div class="d-flex items-center">
                <div class="fw-500 text-dark-1">{{ copydeck.product_list.products[lang ]}}</div>
            </div>
        </div>

        <div class="col-md-2 md:mt-15">
            <div class="">
                <div class="shopCart-products__title d-none md:d-block mb-10">
                    {{ copydeck.product_list.price[lang ]}}
                </div>
                <p>{{ copydeck.product_list.price[lang ]}}</p>
            </div>
        </div>

        <div class="col-md-2" v-if="catalogType === 'list'">
            <div class="">
                <div class="shopCart-products__title">
                    {{ copydeck.product_list.quantity[lang ]}}
                </div>


            </div>
        </div>

        <div class="col-md-2" v-if="catalogType === 'list'">
            <div class="">
                <div class="shopCart-products__title d-none md:d-block mb-10">
                    {{ copydeck.product_list.subtotal[lang ]}}
                </div>

                <p>{{ copydeck.product_list.subtotal[lang ]}}</p>
            </div>
        </div>

        <div class="col-md-2" v-if="catalogType === 'checkout'">
            <div class="">
                <div class="shopCart-products__title d-none md:d-block mb-10">
                    {{ copydeck.product_list.quantity[lang ]}}
                </div>

                <p style="text-align:right">{{ copydeck.product_list.quantity_in_cart[lang ]}}</p>
            </div>
        </div>

        <div class="col-md-2" v-if="catalogType === 'checkout'">
            <div class="">
                <div class="shopCart-products__title d-none md:d-block mb-10">

                </div>

                <p></p>
            </div>
        </div>


    </div>

</template>

<style scoped>

</style>
