<script setup>

import {computed, ref} from "vue";
import {useMainStore}  from "../stores/main";
import {storeToRefs}   from "pinia";

let props = defineProps(["variation","checkoutItem"]);
let emits = defineEmits(['change']);

let store = useMainStore();
const { lang } = storeToRefs(store);


function onChange (option)
{
    let id = option.target.selectedOptions[0].id;
    props.checkoutItem[props.variation.key] = id;
    emits("change", id, props.variation);
}

</script>

<template>
    <div class="col-auto">


        <select class="text-dark-1" :aria-label="variation.listName[lang]"  @change="onChange">
            <option selected>{{ variation.listName[lang] }}</option>
            <option v-for="item in variation.list" :id="item.key">{{ item.name[lang] }}</option>

        </select>
    </div>
</template>

<style scoped>

</style>
