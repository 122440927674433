<script setup>
import { ref } from 'vue';
import { useMainStore } from '../stores/main';
import { storeToRefs } from 'pinia';

let props = defineProps(['variation','checkoutItem']);
let emits = defineEmits(['change']);

let store = useMainStore();
const { lang } = storeToRefs(store);


function getImageFormat (image)
{
    return image.formats.find( f => f.name === 'radio').url;
}

function onChange (option, variation)
{
    props.checkoutItem[props.variation.key] = option.target.id;
    emits("change", option.target.id, variation);
}

</script>

<template>
    <div class="flex-col">
        <label class="group-label">{{ variation.name[lang] }}</label>

        <div class="flex-row">
            <div class="variation-item" v-for="item in variation.list" :key="item.name[lang]">

                <input
                    type="radio"
                    :id="item.key"
                    :name="variation.key"
                    :value="item.key"
                    class="radio-input"
                    @change="(option) => onChange(option, variation)"
                />
                <label :for="item.key" class="radio-label">
                    <div class="variation-color-wrapper">
                        <span class="variation-color" :style="{ backgroundColor: item.color }"></span>
                    </div>
                </label>
            </div>
        </div>
    </div>
</template>

<style scoped>
.variation-item {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    margin-bottom: 10px;
}

.flex-col {
    display: flex;
    flex-direction: column;

}

.flex-row {
    display: flex;
    flex-direction: row;

}
.group-label {
    display: block;
    margin-bottom: 10px;
}

.radio-input {
    display: none;
}

.radio-label {
    cursor: pointer;
}

.radio-input:checked + .radio-label .variation-color-wrapper {
    border: 3px solid var(--color-blue-1);
}

.variation-color-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: 2px solid transparent;
    border-radius: 50%;
    transition: border 0.3s ease;
}

.variation-color {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #000; /* Optional: Add a border */
}


.col-auto {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
</style>
