import { defineStore }                                      from 'pinia';
import {computed, inject, reactive, ref, shallowRef, watch} from "vue";
import { useStorage }                                       from "@vueuse/core";
import {useRoute, useRouter} from "vue-router";

let router;
let route;

export const useMainStore = defineStore('main', () =>
{
    let router   = useRouter();
    let route    = useRoute();

    const availableLang = ["en", "fr"];
    const lang = shallowRef([]);
    lang.value  = inject( 'lang' );

    const otherURL = shallowRef("");
    otherURL.value  = inject( 'otherURL' );

    const copydeck = shallowRef([]);
    copydeck.value = inject('copydeck');


    const catalog = ref([]);
    catalog.value = inject('catalog');

    const catalogType = computed ( () => catalog.value.type ?? "list");

    const mode = ref("order");

    let userInfo = useStorage('userInfo', {}, localStorage);
    let currentOrder = useStorage('currentOrder', [], localStorage);
    let cart = useStorage('cart', [], localStorage);

    watch(userInfo, (value) =>
    {
        console.log("userInfo changed", value);
    });

    const userInfoErrors = ref ( {} );
    const invoice = ref ( {} );

    console.log("define store");


    const ready = ref(true); //if we need to load something, initialize at false and set to true when loading completed

    function resetOrder ()
    {
        console.log("resetOrder");
        currentOrder.value = [];
        userInfo.value = {};
        cart.value = [];
    }
    function changeLang ( strLang )
    {
        if (availableLang.includes(strLang))
        {
            lang.value = strLang;
        }
    }

    function setLangFromRoute ()
    {
        let routeLang = route.params.lang ?? "";

        if (["en","fr"].includes(routeLang))
        {
            changeLang(routeLang);
        }
    }

    let order = computed(() =>
                         {
                             let list = [];

                             for (let i = 0; i < catalog.value.groups.length; i++)
                             {
                                 let group = catalog.value.groups[i];

                                 for (let j = 0; j < group.products.length; j++)
                                 {
                                     let product = group.products[j];
                                     let order = currentOrder.value.find ( (item) => item.id === product.id);

                                     if (order && order.quantity > 0)
                                     {
                                         let quantity = order.quantity;
                                         let sub = (quantity  * product.price).toFixed(2);
                                         list.push({product, quantity, sub});


                                     }
                                 }


                             }


                             return list;
                         });

    let total = computed(() =>
                         {

                                let total = 0;

                                if (catalogType.value === "list")
                                {
                                    for (let i = 0; i < catalog.value.groups.length; i++)
                                    {
                                        let group = catalog.value.groups[i];

                                        for (let j = 0; j < group.products.length; j++)
                                        {
                                            let product = group.products[j];
                                            let order = currentOrder.value.find((item) => item.id === product.id);

                                            if (order && order.quantity > 0)
                                            {
                                                let sub = order.quantity * product.price;
                                                total += sub;
                                            }
                                        }


                                    }
                                }
                                else if (catalogType.value === "checkout")
                                {
                                    for (let i = 0; i < cart.value.length; i++)
                                    {
                                        let item = cart.value[i];
                                        total += item.product.price * item.quantity;
                                    }
                                }

                                return total.toFixed(2);
                         });



    let formIsReady = computed ( () =>
                                 {

                                     let mandatory = ["first_name", "last_name", /*"address", "city", "postal_code", "phone",*/ "email"];


                                     //check for value in userInfo for mandatory field

                                     let countField = 0;

                                     for (let key in userInfo.value)
                                     {

                                         let value = userInfo.value[key];

                                         let hasValue =  (value != null && value !== undefined && value.trim() !== "");

                                         if (hasValue)
                                         {
                                             countField++;
                                         }
                                     }

                                     return mandatory.length <= countField;
                                 });


    function addToCart (product, item, quantity)
    {
        cart.value.push({product, item, quantity});
    }

    function removeFromCart (item)
    {
        cart.value = cart.value.filter( (i) => i !== item.item);
    }

    let computedCart = computed(() =>
    {
        let cc = [];

        for (let i = 0; i < cart.value.length; i++)
        {
            let item = cart.value[i];
            let quantity = item.quantity;
            let product = item.product;
            let sub = (quantity * product.price).toFixed(2);
            let name = product.name;

            let arrKeyVariation = product.variations.map( (v) => v.key);

            let arrVariations = arrKeyVariation.map ( k => product.variations.find( v => v.key === k));

            let arrVariationLocalized = arrVariations.map( v =>
           {
               if (Array.isArray(v.list))
               {
                   let variation = v.list.find(va => va.key == item.item[v.key]);

                   return v.name[lang.value] + "&nbsp:&nbsp;" + variation.name[lang.value];
               }
               else
               {
                   return v.name[lang.value] + "&nbsp:&nbsp;" + item.item[v.key];
               }

           });

            if (arrVariationLocalized.length > 0)
            {
                name += " <br> " + arrVariationLocalized.join(" <br> ");
            }

            let id = product.id;

            /*[{
            "id":9,
            "product":{"name":"Small-format Basket","price":35},
            "quantity":1,
            "sub":"35.00"}]*/

            let r = reactive({id, name, quantity, sub, item, product, removing: false});
            cc.push(r);
        }
        return cc;
    });
    function getProductById (id)
    {
        for (let i = 0; i < catalog.value.groups.length; i++)
        {
            let group = catalog.value.groups[i];

            for (let j = 0; j < group.products.length; j++)
            {
                let product = group.products[j];

                if (product.id === id)
                {
                    return product;
                }
            }
        }

        return null;
    }

    async function submitOrder (client)
    {
        mode.value = "processing";

        let products = [];

        if (catalogType.value === "list")
        {
            products = order.value.map((item) =>
                                       {
                                           let order = currentOrder.value.find((itemOrder) => itemOrder.id === item.product.id);

                                           return {
                                               id:       item.product.id,
                                               product:  {
                                                   name:  item.product.name,
                                                   price: item.product.price,
                                               },
                                               quantity: order.quantity,
                                               sub:      (order.quantity * item.product.price).toFixed(2)
                                           }
                                       });
        }
        else if (catalogType.value === "checkout")
        {
            products = JSON.parse(JSON.stringify(computedCart.value));
        }

        let payload = {
            order: products,
            userInfo: client,
            catalog: catalog.value.id,
            total: parseFloat(total.value).toFixed(2),
            lang: lang.value

        };

        let response = await fetch("/endpoints/submit-order", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        });


        let data = await response.json();

        if (data.success)
        {
            resetOrder();
            mode.value = "ordered";
            invoice.value = data.invoice;
        }
        else
        {
            mode.value = "recap";
            userInfoErrors.value = data.error;
        }

        console.log(data);

    }

    return {
        lang,
        copydeck,
        ready,
        router,
        route,
        cart,
        computedCart,
        catalog,
        catalogType,
        total,
        mode,
        order,
        formIsReady,
        userInfo,
        userInfoErrors,
        currentOrder,
        invoice,
        otherURL,

        addToCart,
        removeFromCart,
        resetOrder,
        submitOrder,
        getProductById

    };
})
