<script setup>
import { ref } from 'vue';
import { useMainStore } from '../stores/main';
import { storeToRefs } from 'pinia';

let props = defineProps(['variation','checkoutItem']);
let emits = defineEmits(['change']);

let store = useMainStore();
const { lang } = storeToRefs(store);


function onChange (option, variation)
{
    if (option.target.value === "")
    {
        delete props.checkoutItem[props.variation.key];
        emits("change", null, variation);
    }
    else
    {
        props.checkoutItem[props.variation.key] = option.target.value;
        emits("change", option.target.value, variation);
    }
}

</script>

<template>
    <div class="col-auto">
        <div class="variation-item">
            <label class="label">{{ variation.text[lang]}}</label>
            <input
                type="text"
                class="text-input"
                @change="(option) => onChange(option, variation)"
            />
        </div>
    </div>
</template>

<style scoped>
.variation-item {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    margin-bottom: 10px;
}

.label {
    cursor: pointer;
    margin-right: 5px;
}

.text-input {
    border: 2px solid var(--color-blue-1);
    border-radius: 5px;
    padding: 5px;
}

.col-auto {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
</style>
