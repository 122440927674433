<script setup>

import {useMainStore} from "../stores/main";
import {storeToRefs}             from "pinia";
import {computed, nextTick, ref} from "vue";

let store = useMainStore();
let {   copydeck, lang, catalogType, computedCart  } = storeToRefs(store);
let { removeFromCart } = store;


let cssClass =  computed(() =>
{
    return computedCart.value.map ( c => c.removing ? "fade-out" : "");
});

let props = defineProps(["order", "total"]);
let itemRefs = ref([]);
function handleRemoveFromCart(item, index) {
    item.removing = true;

    nextTick(() => {
        let element = itemRefs.value[index];
        if (element)
        {
            let height = element.scrollHeight;
            element.style.setProperty('--dynamic-height', `${height}px`);
        }

        setTimeout(() => {
            removeFromCart(item);
        }, 250); // Match the duration of the fade-out animation
    });

}

function getItemCss (item)
{
    if (item.removing)
    {
        return "fade-out";
    }

    return "";
}

</script>

<template>
    <div class="pt-30 pb-15 bg-white border-light rounded-8 bg-light-4">
        <h5 class="px-30 text-20 fw-500">
            {{ copydeck.invoice.your_order[lang] }}
        </h5>

        <div class="d-flex justify-between px-30 mt-25">
            <div class="py-15 fw-500 text-dark-1">{{ copydeck.invoice.products[lang] }}</div>
            <div class="py-15 fw-500 text-dark-1">{{ copydeck.invoice.subtotal[lang] }}</div>
        </div>



        <div class="d-flex justify-between border-top-dark px-30" v-for="item in order" v-if="catalogType === 'list'">
            <div class="py-15 text-grey col-9">{{ item.product.name }}{{ item.quantity > 1 ? ` (x${item.quantity})` : ""}}</div>
            <div class="py-15 text-grey">{{ item.sub}}&nbsp;$</div>
        </div>


        <div
            class="d-flex justify-between border-top-dark px-30"
            v-for="(item, index) in computedCart"
            v-if="catalogType === 'checkout' && computedCart.length > 0"
            :class="cssClass[index]"
            :ref="el => itemRefs[index] = el">

            <div class="py-15 text-grey col-1" ><button class="button -vsm -red-1 text-white mr-2 me-2" @click="handleRemoveFromCart(item, index)">X</button></div>
            <div class="py-15 text-grey col-9" v-html="((item.quantity > 1) ? `(x${item.quantity}) ` : '') + item.name"></div>
            <div class="py-15 text-grey">{{ item.sub}}&nbsp;$</div>
        </div>

        <div
            class="d-flex justify-between border-top-dark px-30"
            v-for="(item, index) in order"
            v-else-if="catalogType === 'checkout' && order.length > 0"
            :class="cssClass[index]"
            :ref="el => itemRefs[index] = el">

            <div class="py-15 text-grey col-9" v-html="((item.quantity > 1) ? `(x${item.quantity}) ` : '') + item.name"></div>
            <div class="py-15 text-grey">{{ item.sub}}&nbsp;$</div>
        </div>

        <div class="d-flex justify-between border-top-dark px-30">
            <div class="py-15 fw-500 text-dark-1 col-9">{{ copydeck.invoice.total[lang] }}</div>
            <div class="py-15 fw-500 text-dark-1">{{ parseFloat(total).toFixed(2) }}&nbsp;$</div>
        </div>
    </div>
</template>

<style scoped lang="scss">

.fade-out {
    animation: fadeOut 0.25s forwards;
    overflow:hidden;
    height: var(--dynamic-height);
}

@keyframes fadeOut {
    from {
        opacity: 1;
        height: var(--dynamic-height);
    }
    to {
        opacity: 0;
        height: 0;
    }
}

</style>
