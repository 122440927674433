<script setup>

import {computed, defineAsyncComponent, ref} from "vue";
import Product from "./product.vue";
import Products_list_head from "./products_list_head.vue";
import {useMainStore} from "../stores/main";
import {storeToRefs} from "pinia";
import Products_list_total from "./products_list_total.vue";
import Variation_list                        from "./variation_list.vue";
import Variation_radiolist_image                   from "./variation_radiolist_image.vue";
import Variation_radiolist_color                   from "./variation_radiolist_color.vue";
import Variation_text                              from "./variation_text.vue";


let props = defineProps(["pathSubmit"]);
let store = useMainStore();
let { catalog, total, mode, copydeck, lang, currentOrder, catalogType } = storeToRefs(store);
const { addToCart, resetOrder, getProductById } = store;

let bShowModal = ref(false);
let currentModal = ref(null);

let currentModalCheckoutQuantity = ref(0);
let currentModalCheckoutItem = ref({});
let currentLargeModalImage = ref(null);
let currentLargeModalForegroundImage  = ref(null);
let base64EncodedImage  = ref(null);

let addToCartEnabled = computed ( () =>
  {
      if (!bShowModal.value)
      {
          return false;
      }

      let cType = catalogType.value;

      if (cType !== "checkout")
      {
          return false;
      }

      let variations = currentModal.value.variations;
      let howManyVariations = variations.length;

      let selectedVariations = Object.keys(currentModalCheckoutItem.value).length;

      return selectedVariations === howManyVariations && currentModalCheckoutQuantity.value > 0;

  })




let groups = computed ( () => catalog.value.groups);

function changeQuantity(product, qty)
{
    //product.quantity = qty;
    let index = currentOrder.value.findIndex( (p) => p.id === product.id);

    if (index < 0)
    {
        index = currentOrder.value.length;
        currentOrder.value.push({ quantity: 0, id: product.id});
    }

    currentOrder.value[index].quantity = qty;
}


function showDetails (id_product)
{
    console.log("show model detail", id_product);
    currentModalCheckoutQuantity.value = 0;
    currentModalCheckoutItem.value = {};
    currentModal.value = getProductById(id_product);
    bShowModal.value = true;
}

function closeModal ()
{
    bShowModal.value = false;
    currentLargeModalImage.value = null;
    currentLargeModalForegroundImage.value = null;
}

function decreaseQuantityModal()
{
    let cType = catalogType.value ?? "list";

    if (cType === "list")
    {
        let index = currentOrder.value.findIndex((p) => p.id === currentModal.value.id);

        let newQty = currentOrder.value[index].quantity - 1;

        if (newQty < 0)
        {
            newQty = 0;
        }

        changeQuantity(currentOrder.value[index], newQty);
    }
    else if (cType === "checkout")
    {
        let newQty = currentModalCheckoutQuantity.value - 1;

        if (newQty < 0)
        {
            newQty = 0;
        }

        currentModalCheckoutQuantity.value = newQty;
    }
}

function increaseQuantityModal()
{
    let cType = catalogType.value ?? "list";

    if (cType === "list")
    {
        let index = currentOrder.value.findIndex((p) => p.id === currentModal.value.id);

        let newQty = currentOrder.value[index].quantity + 1;
        changeQuantity(currentOrder.value[index], newQty);
    }
    else if (cType === "checkout")
    {
        let newQty = currentModalCheckoutQuantity.value + 1;
        currentModalCheckoutQuantity.value = newQty;
    }
}

function getQuantity (id_product)
{
    let index = currentOrder.value.findIndex( (p) => p.id === id_product);

    if (index < 0)
    {
        index = currentOrder.value.length;
        currentOrder.value.push({ quantity: 0, id: id_product});
    }

    return currentOrder.value[index].quantity ?? 0;
}

const loadedComponent = ref([]);

function componentIsLoaded(type) {
    return loadedComponent.value.includes(type);
}

function loadComponent(path) {

    let components = {
        variation_list: Variation_list,
        variation_radiolist_image: Variation_radiolist_image,
        variation_radiolist_color: Variation_radiolist_color,
        variation_text: Variation_text,
    }
    return components[path] ?? null;
}

function addCurrentItemToCart ()
{
    addToCart(currentModal.value, currentModalCheckoutItem.value, currentModalCheckoutQuantity.value);
    closeModal();
}

async function convertImageToBase64(imageUrl) {
    try {
        // Fetch the image as a Blob
        const response = await fetch(imageUrl);

        // Check if the request was successful
        if (!response.ok) throw new Error('Failed to fetch image');

        const blob = await response.blob();

        // Create a new FileReader to read the Blob as data URL
        return await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
}

function onCartItemChange ( key, el)
{
    if (el?.list)
    {
        let item = el.list.find ( e => e.key == key);

        if (item?.image && (item?.hasImage == 1 || el.typeRadio == "image"))
        {

            //this is a clusterfuck!!!
            let layer = item.layer ?? "1";

            if (layer !== "2")
            {
                let format = (item.image.formats ?? []).find( f => f.name === 'radio');


                if (format)
                {
                    currentLargeModalImage.value = format.url;
                }
            }
            else
            {
                let format = (item.image.formats ?? []).find( f => f.name === 'square_large');
                if (format)
                {


                    currentLargeModalForegroundImage.value = format.url;
                }
            }


        }
        else if (item?.color)
        {
            document.querySelector('.text-overlay').style.setProperty('--tint-color-text', item.color);
        }
    }



    console.log("onCartItemChange", el, key)
}

let currentLargeImage = computed ( () =>
{
    if (catalogType.value === "list")
    {
        return currentModal?.value?.img_large ?? "";
    }
    else if (catalogType.value === "checkout")
    {
        return currentLargeModalImage.value ?? currentModal?.value?.img_large ?? "";
    }

})

let currentModelOrderProduct = computed ( () => {
    let index = currentOrder.value.findIndex( (p) => p.id === currentModal?.value?.id ?? -1);

    if (index < 0)
    {
        return 0;
    }
    return currentOrder.value[index] ?? {};

})

function getVariationSubType (variation)
{
    if (variation.type == "radiolist")
    {
        return "_" + variation.typeRadio;
    }

    return "";
}

</script>

<template>

    <div>
        <div class="px-30 pr-60 py-25 pb-20  text-white bg-dark-1">
            <h3 class="text-blue-1 pb-10">{{ catalog.description.title[lang] }}</h3>
            <p v-html="catalog.description.details[lang]"></p>
        </div>
        <div class="px-30 pr-60 py-25 rounded-8 bg-light-6 md:d-none">
            <products_list_head></products_list_head>
        </div>

        <div class="px-30 pr-60 py-25 rounded-8 bg-light-6 md:d-block d-none text-center">
            <h3> {{ copydeck.product_list.title_products_mobile[lang] }} </h3>
        </div>
    </div>

    <div class="px-30 pr-60 md:px-0" v-for="group in groups">
        <span v-if="groups.length > 1 && group.name && group.name !== ''">{{ group.name }}</span>
        <product
            v-for="p in group.products"
            :item="p"
            @show-details="showDetails"
            @change-quantity="(qty) => changeQuantity(p, qty)"
            @change="(qty) => changeQuantity(p, qty)"></product>
    </div>

    <div class="px-30 pr-60 py-25 rounded-8 bg-light-6">
        <products_list_total :total="total"></products_list_total>
    </div>

    <div class="px-30 pr-60 py-25 rounded-8 d-flex justify-content-around">

        <button
            @click="resetOrder"
            class="button -md -blue-1 text-white mr-2 me-2"> {{ copydeck.button.reset[lang] }} </button>


        <router-link
            :to="pathSubmit">

            <button
            :disabled="total == 0"
            class="button -md -blue-1  text-white"> {{ copydeck.button.submit[lang] }} </button>
        </router-link>
    </div>


    <div id="myModal" class="modal " v-if="bShowModal">
        <!-- Modal Content -->
        <div class="modal-content" >



            <div class="row y-gap-60 justify-between items-center">
                <div class="col-lg-6">
                    <div class="js-shop-slider">
                        <div class="shopSingle-preview__image js-slider-slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-watch-progress swiper-backface-hidden">
                            <div>

                                <div class="" role="group" data-martin="hello"  style="width: 100%;">
                                        <div class="ratio ratio-63:57">
                                            <img class="absolute-full-center rounded-8" :src="currentLargeImage" :alt="currentModal.name">

                                            <img
                                                class="absolute-full-center rounded-8 text-overlay"
                                                :style="{ 'mask-image': `url(${currentLargeModalForegroundImage})` }"
                                                v-if="currentLargeModalForegroundImage"
                                                src="/images/temp.png"
                                                :alt="currentModal.name">


                                        </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-5">
                    <div class="d-flex justify-content-end">
                        <button
                            @click="closeModal"
                            class="button -sm -dark-1 text-white mr-2 d-block"> X </button>
                    </div>
                    <div class="pb-90 md:pb-0">
                        <h2 class="text-30 fw-500 mt-4">{{ currentModal.name }}</h2>
                        <div class="text-24 fw-500 text-blue-1 mt-15">{{ parseFloat(currentModal.price).toFixed(2) }}&nbsp;$</div>

                        <div class="mt-30" v-html="currentModal.description">

                        </div>

                        <div class="shopSingle-info__action row x-gap-20 y-gap-20 pt-30" v-for="variation in currentModal.variations">

                            <component
                                :is="loadComponent(`variation_${variation.type}${getVariationSubType(variation)}`)"
                                :variation="variation"
                                @change="onCartItemChange"
                                :checkoutItem="currentModalCheckoutItem">

                            </component>
                        </div>




                        <div class="shopSingle-info__action row x-gap-20 y-gap-20 pt-30" v-if="catalogType == 'list'">
                            <div class="col-auto">
                                <div class="input-counter js-input-counter">
                                    <input class="input-counter__counter" type="number" placeholder="0" :value="getQuantity(currentModal.id)">

                                    <div class="input-counter__controls">
                                        <button class="input-counter__up js-down"  @click="decreaseQuantityModal()">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-minus icon"><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                                        </button>

                                        <button class="input-counter__down js-up" @click="increaseQuantityModal()">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus icon"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                                        </button>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div class="shopSingle-info__action row x-gap-20 y-gap-20 pt-30" v-else-if="catalogType == 'checkout'">

                            <div class="col-auto">
                                <div class="input-counter js-input-counter">
                                    <input class="input-counter__counter" type="number" placeholder="0" :value="currentModalCheckoutQuantity">

                                    <div class="input-counter__controls">
                                        <button class="input-counter__up js-down"  @click="decreaseQuantityModal()">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-minus icon"><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                                        </button>

                                        <button class="input-counter__down js-up" @click="increaseQuantityModal()">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus icon"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                                        </button>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div class="shopSingle-info__action row x-gap-20 y-gap-20 pt-30" v-if="catalogType == 'checkout'">
                            <div class="col-auto">
                                <button
                                    class="button -md -blue-1 text-white mr-2 me-2" :disabled="!addToCartEnabled" @click="addCurrentItemToCart"> {{ copydeck.checkout.btn_add_to_cart[lang] }} </button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </div>
    </div>

</template>

<style scoped>



.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 10; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    display: flex;
    align-items: center; /* Align vertical */
    justify-content: center; /* Align horizontal */
}

/* Modal Content */
.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
}

.text-overlay {
    --tint-color-text: #FFFFFF;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--tint-color-text); /* Use the hex color as the tint */
    /* Applies the mask based on image B */
    mask-size: cover;
    pointer-events: none;
}

</style>
